<script lang="ts" setup>
import { Resource } from '@/helpers/Interfaces';

import { DynamicGridColumn } from '../../services/DynamicGridService';
import StarRating from '@/components/common/StarRating.vue';

defineOptions({
    name: 'PriorityFormatter',
    components: {
        StarRating
    }
});

interface Props
{
    configuration: DynamicGridColumn
    item: Resource<any>
}

const props = defineProps({
  "configuration": null,
  "item": null
});
</script>

<template>
    <star-rating v-if="props.item.result[configuration.field]" :max="5" disabled-click="true" v-model="props.item.result[configuration.field].priority"></star-rating>
</template>