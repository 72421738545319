import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';


export const Definition: BlueprintDefinition = {
    type: 'button',
    name: '[[[Przycisk]]]',
    icon: 'fa-mouse',
    group: 'primary',
    position: 10
};

export enum ButtonMethodTypes {
    Get = 'get',
    Post = 'post',
    Put = 'put',
    Delete = 'delete',
}

export enum ButtonVariantTypes {
    Primary = "primary",
    Secondary = "secondary",
    Success = "success",
    Danger = "danger",
    Warning = "warning",
    Info = "info",
    Light = "light",
    Dark = "dark",
    Link = "link",
}

export interface ButtonContract extends Blueprint, VisibleBlueprint, HasHelp, HasWidth, HasLabel
{
    endpointMethodType: string;
    endpoint: string;
    body: string;

    variant: string;

    postSuccessAction: string;
    postErrorAction: string;
}

export class ButtonType implements ButtonContract
{
    public id: string;
    public type: string;
    public name: string;
    public minWidth: number;
    public width: number;
    public disabled?: boolean;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public help: Record<string, string>;
    public exceptWidth?: number[];
    public label: Record<string, string>;
    public showLabel: boolean;

    public endpointMethodType: string;
    public endpoint: string;
    public body: string;
    public variant: string;

    public postSuccessAction: string;
    public postErrorAction: string;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Tekst' };
        this.showLabel = true;
        this.help = {};
        this.minWidth = 1;
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;

        this.endpointMethodType = ButtonMethodTypes.Get;
        this.endpoint = '';
        this.body = '';
        this.variant = ButtonVariantTypes.Primary;
        this.postSuccessAction = '';
        this.postErrorAction = '';
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(1, Math.max(this.minWidth, width));
    }
}
