<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import Gs1Service, { CalculateCheckDigitModel, CalculateCheckDigitResult } from '@/modules/wms/common/services/Gs1Service';
import { Form, FormType } from '@/helpers/Form';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';


const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $log } = useLogging();

const dropdownRef = ref<any>(null);

const form = reactive<FormType<CalculateCheckDigitModel>>(Form.create<CalculateCheckDigitModel>({
    input: ''
}));

const checkDigitResult = ref<CalculateCheckDigitResult>(null);

onMounted(async () =>
{
    form.reset();
    checkDigitResult.value = null;
});

const calculate = async (): Promise<boolean> =>
{
    form.$errors.clear();

    form.wait();

    try
    {
        const result = await Gs1Service.calculateCheckDigit(form);

        checkDigitResult.value = result;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message),
            422: (ex: any) => form.$errors.record(ex.data.errors)
        });

        checkDigitResult.value = null;
    }
    finally
    {
        form.continue();
    }

    return true;
};

const clear = () : void =>
{
    form.reset();
    checkDigitResult.value = null;
};

</script>

<template>
    <ideo-dropdown ref="dropdownRef" class="notifications-dropdown" variant="link" no-caret right @show="clear">
        <template #button-content>
            <span class="position-relative">
                <div>{{ $t("[[[CK]]]") }}</div>
            </span>
        </template>

        <div class="text-center mt-2 mb-3">
            <ideo-form-group :invalid-feedback="form.$errors.first('input')" :state="form.$errors.state('input')">
                <ideo-form-input v-model="form.input" name="input" @keydown.enter="calculate" @input="calculate" />
            </ideo-form-group>
        </div>

        <div v-if="checkDigitResult && checkDigitResult.result != null" class="text-center p-3">
            <h5>{{ $t(`[[[Cyfra kontrolna: %0|||${checkDigitResult.result.toString()}]]]`) }}</h5>
        </div>
    </ideo-dropdown>
</template>

<style lang="scss">
.notifications-dropdown {
    font-size: 20px;

    .dropdown {
        &-menu {
            padding: 0.5rem 1rem;
            width: 300px;
            z-index: 10001;
            max-height: calc(100vh - 65px);
            overflow: auto;
        }
        &-toggle {
            i.fa-bell {
                font-size: 1.125rem;
                color: var(--bs-navbar-color);
            }
        }
        &-item {
            white-space: unset;
            padding: 0.625rem;
            border-radius: 0.313rem;
            background: var(--bs-tertiary-bg);
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            &:hover {
                background: var(--bs-secondary-bg);
            }
            i {
                font-size: 1rem;
            }
            .title, .date {
                display: block;
                color: var(--bs-body-color);
            }
            .title {
                margin-bottom: 0.625rem;
            }
        }
        .show-all {
            text-transform: unset;
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
}
</style>
