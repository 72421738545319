import { axios } from '@/plugins/axios';


export default class Gs1Service
{
    public static async calculateCheckDigit(model: CalculateCheckDigitModel): Promise<CalculateCheckDigitResult>
    {
        return (await axios.post<CalculateCheckDigitResult>('admin/gs1/calcualate-check-digit', model)).data;
    }
}

export interface CalculateCheckDigitResult {
    result: number;
}

export interface CalculateCheckDigitModel {
    input: string;
}
